import React, { Component, useContext } from "react";
import PropTypes from "prop-types";
import Div100vh from "react-div-100vh";
import { graphql, Link } from "gatsby";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import AlphaVideo from "~components/AlphaVideo";
import Layout from "~components/Layout";
import SEO from "~components/SEO";
import SignUpForm from "~components/SignUpForm";

class ResultsPageComponent extends Component {
  state = {
    formAccepted: false
  };

  resultPath = ``;

  timeouts = [];

  componentDidMount() {
    const { appContext, navigate } = this.props;

    if (!appContext.quizResult || appContext.quizState === -1) {
      this.timeouts.push(
        setTimeout(() => {
          this.clearQuiz();

          navigate(`/start`);
        }, 99)
      );
    }
  }

  componentWillUnmount() {
    this.clearQuiz();
    this.clearTimeouts();
  }

  clearQuiz = () => {
    const { appContext } = this.props;

    appContext.setQuizResult(null);
    appContext.setQuizState(-1);
  };

  clearTimeouts = () => {
    if (this.timeouts.length > 0) {
      this.timeouts.forEach(timeout => {
        clearTimeout(timeout);
      });
    }
  };

  //

  onSubmit = () => {
    this.setState({
      formAccepted: true
    });
  };

  //

  render() {
    const { formAccepted } = this.state;
    const { appContext, documentContext, frontmatter } = this.props;

    let personaBackgroundColor = ``;
    let personaTextColor = ``;

    if (appContext.quizResult) {
      appContext.quizResult.frontmatter.colorClasses
        .split(` `)
        .forEach((colorClass, index) => {
          if (index === 0) {
            personaBackgroundColor = colorClass.trim().replace(`bg-`, ``);
          } else {
            personaTextColor = colorClass.trim();
          }
        });
    }

    //

    return (
      <>
        <SEO
          frontmatterTitle={frontmatter.title}
          frontmatterDescription={frontmatter.seoDescription}
          frontmatterKeywords={frontmatter.seoKeywords}
        />

        <Layout
          className={`results-page relative bg-${personaBackgroundColor} overflow-hidden`}
          footerClass={
            appContext.quizResult &&
            appContext.quizResult.frontmatter.title === `Whole Foodie`
              ? `black`
              : `white`
          }
          footerType="desktop-only"
          headerClass={
            appContext.quizResult &&
            appContext.quizResult.frontmatter.title === `Whole Foodie`
              ? `black`
              : `white`
          }
        >
          {(documentContext.device === `desktop` && (
            <Div100vh className="relative flex flex-col items-center justify-center">
              <div className="animation-appear w-1/2 absolute z-10">
                <AlphaVideo cursorMax src="/uploads/alpha-video-1.mp4" />
              </div>

              <div className="relative z-20 grid">
                <section
                  className="results-page__form animation-appear grid-end-10 md:grid-end-12 grid-start-8 md:grid-start-7 relative block py-6 bg-black text-white"
                  style={{ animationDelay: `150ms` }}
                >
                  <SignUpForm onSubmit={this.onSubmit} />
                </section>

                {appContext.quizResult && (
                  <div
                    className={`animation-appear grid-end-10 md:grid-end-12 grid-start-8 md:grid-start-7 flex items-center justify-center text-${personaTextColor}`}
                    style={{ animationDelay: `300ms` }}
                  >
                    <Link
                      className="button button--white relative block py-4 px-8 mt-8 b3 text-white"
                      to={appContext.quizResult.fields.slug}
                    >
                      {formAccepted
                        ? `Go to results`
                        : `No thanks, I’d rather just see the results`}
                    </Link>
                  </div>
                )}
              </div>
            </Div100vh>
          )) || (
            <div className="results-page__inner w-screen relative flex flex-col items-center justify-center">
              <div className="relative grid z-20 pt-12 xs:pt-32">
                <section
                  className="results-page__form animation-appear sm:grid-end-20 xs:grid-end-24 sm:grid-start-3 xs:grid-start-1 relative block mx-auto pt-12 pb-24 bg-black text-white"
                  style={{ animationDelay: `150ms` }}
                >
                  <SignUpForm onSubmit={this.onSubmit} />
                </section>

                {appContext.quizResult && (
                  <div
                    className={`results-page__skip grid-end-24 animation-appear relative block mt-24 mx-auto text-${personaTextColor}`}
                    style={{ animationDelay: `300ms` }}
                  >
                    <Link
                      className={`button button--${personaTextColor} relative block py-4 px-4 b3 text-center text-${personaTextColor}`}
                      to={appContext.quizResult.fields.slug}
                    >
                      {formAccepted
                        ? `Go to results`
                        : `No thanks, I’d rather just see the results`}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          )}
        </Layout>
      </>
    );
  }
}

ResultsPageComponent.defaultProps = {
  frontmatter: {
    title: ``,
    heading: ``,
    content: ``,
    seoDescription: ``,
    seoKeywords: ``
  }
};

ResultsPageComponent.propTypes = {
  appContext: PropTypes.shape({
    setActivePath: PropTypes.func,
    setQuizResult: PropTypes.func,
    setQuizState: PropTypes.func,
    quizResult: PropTypes.shape({
      fields: PropTypes.shape({
        slug: PropTypes.string
      }),
      frontmatter: PropTypes.shape({
        colorClasses: PropTypes.string.isRequired,
        title: PropTypes.string
      })
    }),
    quizState: PropTypes.number
  }).isRequired,
  documentContext: PropTypes.shape({
    device: PropTypes.string.isRequired
  }).isRequired,
  frontmatter: PropTypes.shape({
    title: PropTypes.string,
    heading: PropTypes.string,
    content: PropTypes.string,
    seoDescription: PropTypes.string,
    seoKeywords: PropTypes.string
  }),
  navigate: PropTypes.func.isRequired
};

const ResultsPage = ({ data, navigate }) => {
  const appContext = useContext(AppContext);
  const documentContext = useContext(DocumentContext);
  const { frontmatter } = data.markdownRemark;
  const personas = data.allMarkdownRemark.edges;

  return (
    <ResultsPageComponent
      appContext={appContext}
      documentContext={documentContext}
      frontmatter={frontmatter}
      navigate={navigate}
      personas={personas}
    />
  );
};

export default ResultsPage;

ResultsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({}).isRequired
    }),
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({})).isRequired
    }).isRequired
  }).isRequired,
  navigate: PropTypes.func.isRequired
};

export const resultsPageQuery = graphql`
  query ResultsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        seoDescription
        seoKeywords
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "persona-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            colorClasses
          }
        }
      }
    }
  }
`;
